import * as React from "react"
import { Formik } from "formik"
const axios = require("axios").default

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => {
  const [msg, setMsg] = React.useState("")
  const [isSent, setIsSent] = React.useState(false)

  return (
    <Layout
      className="text-center"
      style={{ backgroundColor: "#d8d3c0", overflowY: "scroll" }}
    >
      <Seo title="Contact Us" />
      <div
        className="max-w-4xl mx-auto px-4 md:px-0 pb-24 tracking-wider pt-64 md:pt-24"
        style={{ color: "#958e74", backgroundColor: "#f3ece6" }}
      >
        <h1 className="text-3xl md:text-5xl font-semibold text-primary font-header tracking-widest">
          Claydence
        </h1>
        <p className="font-body mt-4">Freehold, 28 Apartments, Joo Chiat</p>
        <div className="py-8 text-base md:text-xl font-semibold text-primary font-body">
          <iframe
            className="w-full aspect-video mb-12"
            src="https://www.youtube.com/embed/ICui0hPGBbk?si=V7uxCk1Tasq2zrMc?controls=0"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </Layout>
  )
}

function Row({ className, title, children }: any) {
  return (
    <tr className="even:bg-white/30">
      <td className="p-2">{title}</td>
      <td className="p-2">{children}</td>
    </tr>
  )
}

export default IndexPage
